import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, API_URL, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, STORAGE_URL } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { GetOrganizationRequest, GetOrganizationsRequest, UpdateOrganizationStateRequest } from "../../../../repository/UserManagement/organization_pb"
import { dismissMessage, getOrganization, getOrganizationClientPortalSetting, reset, resetOrganization, updateOrganization, updatePortalStatus, updateStatus } from "./OrganizationDetailsPageSlice"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { OrganizationsForm } from "../../../forms/Organizations/OrganizationsForm"
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb"
import { TextView } from "../../../common/DataView/TextView"
import { ProfileView } from "../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"
import { GetOrganizationClientPortalSettingRequest, UpdateStatusRequest } from "../../../../repository/UserManagement/organizationclientportalsetting_pb"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetOrganizationRequest;
let reqPortal: GetOrganizationClientPortalSettingRequest;

let org: number = -1;

let getOrganizationsPromise: any;
let getPortalPromise: any;
let current: AuthenticateReply.AsObject;


const key = "organizations/details"
export const OrganizationDetailsPage: React.FunctionComponent = () => {


    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        organization: any,
        portalSetting: any,
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.organizationDetailsPage.isChangeStateLoading,
            message: state.organizationDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.organizationDetailsPage.isLoading,
            organization: state.organizationDetailsPage.item,
            portalSetting: state.organizationDetailsPage.portalSetting
        }
    })

    const statusActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const portalStatusActionConfirmationcalloutTargetId = useId('portalactivity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1)
            return statusActionConfirmationcalloutTargetId;
        else if (action == 2)
            return portalStatusActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }


    useEffect(() => {
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {
            req = new GetOrganizationRequest();
            reqPortal = new GetOrganizationClientPortalSettingRequest();

            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
            if (id && !Number.isNaN(id)) {
                req.setId(Number(id))
                reqPortal.setId(Number(id))
                getOrganizationsPromise = dispatch(getOrganization({ body: req, headers: getHeaders() }))
                getPortalPromise = dispatch(getOrganizationClientPortalSetting({ body: req, headers: getHeaders() }))

            } else {
                navigate(-1)
            }
        }
        return () => { //clean up
            getOrganizationsPromise?.abort();
            getPortalPromise?.abort()
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),

            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetOrganization())
                getOrganizationsPromise = dispatch(getOrganization({ body: req, headers: getHeaders() }))
                getPortalPromise = dispatch(getOrganizationClientPortalSetting({ body: req, headers: getHeaders() }))

            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t('edit'),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }

        },
        {
            key: 'status',
            text: (state.organization && state.organization.isActive) ? t("deactivateOrganization") : t("activateOrganization"),
            title: (state.organization && state.organization.isActive) ? t("deactivateOrganization") : t("activateOrganization"),
            iconProps: { id: statusActionConfirmationcalloutTargetId, iconName: (state.organization && state.organization.isActive) ? "UserRemove" : "UserFollowed", styles: { root: { fontWeight: (state.organization && state.organization.isActive) ? undefined : "bold" } } },
            disabled: state.isLoading,

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'portalStatus',
            text: (state.portalSetting && state.portalSetting.isActive) ? t("deactivatePortal") : t("activatePortal"),
            title: (state.portalSetting && state.portalSetting.isActive) ? t("deactivatePortal") : t("activatePortal"),
            iconProps: { id: portalStatusActionConfirmationcalloutTargetId, iconName: (state.portalSetting && state.portalSetting.isActive) ? "ReleaseGateError" : "ReleaseGateCheck", styles: { root: { fontWeight: (state.portalSetting && state.portalSetting.isActive) ? undefined : "bold" } } },
            disabled:  state.isLoading,

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.organization?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);;
                }
            }
        },
    ];

    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'enterOrganization',
            text: t('enterOrganization'),
            title: t('enterOrganization'),
            iconProps: { iconName: 'Signin' },
            disabled: state.isLoading,
            onClick: () => {
                window.location.href = "/?org=" + state.organization?.id;

            }
        },

    ];

    return (
        <Stack >

            <Popup isOpen={(currentAction == 101)} title={t("editOrganization")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.organization} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateOrganization(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareOrganization")} value={`https://${window.location.host}/management/organizations/${state.organization?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 360,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? (state.organization?.isActive) ? t("deactivateOrganization") : t("activateOrganization") : currentAction == 2 ? (state.portalSetting?.isActive) ? t("deactivatePortal") : t("activatePortal") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? (state.organization?.isActive) ? t("deactivateOrganizationDesc") : t("activateOrganizationDesc") : currentAction == 2 ? (state.portalSetting?.isActive) ? t("deactivatePortalDesc") : t("activatePortalDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.organization) {
                                        if (currentAction == 1) {
                                            let req = new UpdateOrganizationStateRequest();
                                            req.setId(state.organization?.id)
                                            req.setIsactive(!state.organization?.isActive)
                                            dispatch(updateStatus({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new UpdateStatusRequest();
                                            req.setId(state.portalSetting?.id)
                                            req.setIsactive(!state.portalSetting?.isActive)
                                            dispatch(updatePortalStatus({ body: req, headers: getHeaders() }))
                                        }
                                    }
                                }} text={(currentAction == 1 ? (state.organization?.isActive) ? t("deactivate") : t("activate") : currentAction == 2 ? (state.portalSetting?.isActive) ? t("deactivatePortal") : t("activatePortal") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}




            {state.isLoading ? <ProgressIndicator /> :

                !state.organization ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                            farItems={_farOptions}
                        />

                        <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.organization?.id} />


                                <TextView label={t("createdDate")} value={state.organization?.createdDate} />

                                {state.organization?.addedBy ? <ProfileView label={t("addedBy")} value={state.organization?.addedBy == "" ? state.organization?.addedById : state.organization?.addedBy}
                                    url={"/management/users/" + state.organization?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.organization?.addedBy == "" ? state.organization?.addedById : state.organization?.addedBy}
                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <ProfileView label={t("organizationName")} value={state.organization?.name}
                                url={"#"}
                                text={state.organization?.name}
                                imageUrl={ORGANIZATION_LOGO_URL + state.organization?.logo?.Name}
                                size={PersonaSize.size40} />

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("status")} iconName={state.organization?.isActive != undefined ? state.organization?.isActive ? "Accept" : "Cancel" : undefined} value={state.organization?.isActive != undefined ? state.organization?.isActive ? t("active") : t("inactive") : ""} />
                               
                                <TextView label={t("portalStatus")} iconName={state.portalSetting?.isActive != undefined ? state.portalSetting?.isActive ? "Accept" : "Cancel" : undefined} value={state.portalSetting?.isActive != undefined ? state.portalSetting?.isActive ? t("active") : t("inactive") : ""} />

                                <TextView label={t("domain")} value={state.organization?.domain} />

                                <TextView label={t("orgSubscription")} value={state.organization?.subscription == 1 ? t("standard") :
                                    state.organization?.subscription == 2 ? t("premium") : state.organization?.subscription == 3 ? t("enterprises") : ""} />

                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("email")} value={state.organization?.email} />
                                <TextView label={t("phonenumber")} value={state.organization?.phonenumber} />
                            </Stack>

                            <TextView label={t("address")} value={state.organization?.address} />

                            {state.organization?.parentOrganizationId ?

                                <ProfileView label={t("parentOrganization")} value={(state.organization?.parentOrganizationName) ? state.organization?.parentOrganizationName : state.organization?.parentOrganizationId + ""}
                                    url={"/organizations/" + state.organization?.parentOrganizationId + ((org == -1) ? "" : "?org=" + org)}
                                    text={(state.organization?.parentOrganizationName) ? state.organization?.parentOrganizationName : state.organization?.parentOrganizationId + ""}
                                    size={PersonaSize.size40} onLinkClick={(e) => {
                                        if (e) {
                                            e.preventDefault();
                                            req.setId(state.organization.parentOrganizationId)
                                            getOrganizationsPromise = dispatch(getOrganization({ body: req, headers: getHeaders() }))
                                        }
                                    }} />

                                : undefined}

                        </Stack>
                    </div>

            }
        </Stack>
    );
}
/* req.setId(state.organization.parentOrganizationId)
                                        getOrganizationsPromise = dispatch(getOrganization({ body: req, headers: getHeaders() })) */



